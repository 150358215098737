<template>
  <a-form-model
    ref="ruleForm"
    :model="form"
    @submit.prevent="onSubmit"
  >
    <fieldset :disabled="loading || loadingSubmit">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <LinkRoute
            to="/price-formula"
            class="text-primary"
          >
            {{ $t('sideBar.price_formula') }}
          </LinkRoute>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          {{ $t('utils.add') }} {{ $t('sideBar.price_formula') }}
        </a-breadcrumb-item>
      </a-breadcrumb>

      <a-card class="my-2 rounded">
        <p class="pbz-font subtitle-md-medium mb-4">
          {{ $t('utils.add') }} {{ $t('sideBar.price_formula') }}
        </p>

        <label class="mb-1">
          {{ $t('price_setting.label_formula') }} <sup class="text-danger">*</sup>
        </label>
        <a-form-model-item
          prop="name"
          class="mb-3"
          :rules="[
            {
              required: true,
              whitespace: false,
              message: $t('price_setting.error_formula_name'),
            },
            {
              pattern: /^[0-9a-zA-Z_]*$/,
              message: $t('price_setting.error_formula_pattern'),
            },
            {
              max: 30,
              message: $t('price_setting.error_max_formula'),
            }
          ]"
        >
          <a-input v-model="form.name" size="large" />
        </a-form-model-item>

        <label class="mb-1">
          {{ $t('price_setting.label_description') }}
        </label>
        <a-form-model-item
          prop="description"
          class="mb-3"
          :rules="[{
            max: 30,
            message: $t('price_setting.error_description'),
          }]"
        >
          <a-input v-model="form.description" size="large" />
        </a-form-model-item>

        <label class="mb-1">
          {{ $t('price_formula.rounding') }}
        </label>
        <a-form-model-item prop="rounding">
          <a-select
            v-model="form.rounding"
            class="w-100 h-48px"
            size="large"
          >
            <a-select-option :value="1000">
              1.000
            </a-select-option>
            <a-select-option :value="10000">
              10.000
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <p class="pbz-font body-sm-bold mb-4">
          {{ $t('sideBar.formula_variable') }}
        </p>
        <FormFormulaVariable
          :list="form.formulaVariables"
          :formula-use="formulaNames"
          :load-formula="loadFormula"
          :formulas="formulas"
          @dropdownVisibleChange="getFormulaVariables"
          @changeVarName="changeVarName"
          @onRemoveItem="onRemoveFormulaItem"
        />

        <a-button
          size="large"
          icon="plus"
          type="primary"
          class="pbz-font mb-3"
          @click="onAddFormula"
        >
          {{ $t('utils.add') }} {{ $t('utils.variable') }}
        </a-button>

        <div class="d-flex flex-row">
          <p class="pbz-font body-sm-bold my-4">
            Formula
          </p>
          <div class="ml-auto text-right">
            <a-button
              size="large"
              type="primary"
              class="pbz-font my-3"
              @click="onShowModal"
            >
              {{ $t('utils.insert') }} {{ $t('utils.variable') }}
              <a-icon type="caret-down" />
            </a-button>
            <ModalVariabel
              :visible="showModal"
              :formula="formula_array"
              :variables-global="variablesGlobal"
              :variables-formula="variablesFormula"
              :variables-product="variablesProduct"
              :constant="constant"
              :input-const-width="inputConstWidth"
              @cancel="handleCancel"
              @onClickBracket="onClickBracket"
              @onClickItem="onClickItemModal"
              @onClickApply="onClickApply"
              @onClickConstant="onClickConstant"
              @onChangeConstant="onChangeConstant"
              @onBlurConstant="onBlurConstant"
              @onKeyupConstant="onKeyupConstant"
              @onClickBack="formula_array.pop()"
            />
          </div>
        </div>
        <a-form-model-item
          prop="formula"
          :rules="[{
            required: true,
            message: $t('price_setting.error_formula_empty'),
          }]"
        >
          <a-textarea
            v-model="form.formula"
            read-only
            :rows="5"
            :placeholder="$t('utils.example') + ': {base_price}*{product_weight}*{variable_formula}'"
            class="font-weight-bold text-monospace"
          />
        </a-form-model-item>
      </a-card>
      <a-form-model-item class="text-right mt-3">
        <a-button
          type="link"
          size="large"
          class="text-primary font-weight-bold mr-2"
          :disabled="loadingSubmit"
          @click="$router.push({ path: '/price-formula', query: $route.query })"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          v-if="permission.includes('WRITE')"
          size="large"
          type="primary"
          html-type="submit"
          class="pbz-font font-weight-bold"
          :loading="loadingSubmit"
        >
          {{ $t('utils.save') }}
        </a-button>
      </a-form-model-item>
    </fieldset>
  </a-form-model>
</template>

<script>
import { getPriceVariables, crudPriceFormula } from '@/api/price'
import isNumber from '@/utils/typeChecking/isNumber'
import FormFormulaVariable from '@/components/Price/Price-Formula/FormFormulaVariable'
import ModalVariabel from '@/views/price/price-formula/modal'
import LinkRoute from '@/components/ConsoleLink/LinkRoute.vue'
const PAGE_LIMIT = {
  page: 1,
  limit: 50,
}
const isOperator = val => ['+', '-', '*', '/'].includes(val)
let incrementId = 0
export default {
  components: {
    FormFormulaVariable,
    ModalVariabel,
    LinkRoute,
  },
  data() {
    return {
      form: {
        name: '',
        description: '',
        rounding: 1000,
        formula: '',
        formulaVariables: [],
      },
      showModal: false,
      loading: false,
      loadFormula: true,
      loadingSubmit: false,
      variablesGlobal: [],
      variablesFormula: [],
      variablesProduct: [],
      formula: '',
      formula_array: [],
      formulaInit: [],
      formulas: [],
      rounding: 1000,
      formulaNames: [],
      temporary: [],
      constant: null,
      inputConstWidth: '25px',
      errorGetFormula: false,
    }
  },
  computed: {
    permission() {
      return this.$store.getters['user/can']('price-formula')
    },
  },
  methods: {
    async fetchGlobalVariables() {
      this.loading = true
      await getPriceVariables({
        type: 'GLOBAL',
        params: {
          business_id: this.$route.query.business_id,
          ...PAGE_LIMIT,
        },
      })
      .then(({ data }) => this.variablesGlobal = data?.data || [])
      .catch(() => {
        this.variablesGlobal = []
        this.$message.error(this.$t('price_formula.error_get_global'))
      })
      .finally(() => this.loading = false)
    },
    async fetchProductVariables() {
      this.loading = true
      await getPriceVariables({
        type: 'PRODUCT',
        params: {
          business_id: this.$route.query.business_id,
          ...PAGE_LIMIT,
        },
      })
      .then(({ data }) => this.variablesProduct = data?.data || [])
      .catch(() => {
        this.variablesProduct = []
        this.$message.error(this.$t('price_formula.error_get_product'))
      })
      .finally(() => this.loading = false)
    },
    async getFormulaVariables(open) {
      if (open && this.loadFormula) {
        this.loading = true
        this.loadFormula = true

        await getPriceVariables({
          type: 'FORMULA',
          params: {
            business_id: this.$route.query.business_id,
            ...PAGE_LIMIT,
          },
        })
        .then(({ data }) => {
          this.errorGetFormula = false
          this.formulas = data?.data || []
        })
        .catch(() => {
          this.errorGetFormula = true
          this.formulas = []
          this.$message.error(this.$t('price_formula.error_get_formula'))
        })
        .finally(() => {
          this.loading = false
          this.loadFormula = false
        })
      }
      else if (!open && this.errorGetFormula) {
        this.loadFormula = true
      }
    },
    onShowModal() {
      const { form, formulas } = this
      
      this.temporary = form.formulaVariables.map(variable => ({
        id: variable.id,
        value: variable.value,
      }))

      this.variablesFormula = this.temporary.map((value) => {
        const x = formulas.find((v) => v.id === value.id)
        if (x && value.value) {
          return {
            ...x,
            value: value.value,
          }
        }
        return null
      }).filter(Boolean)
      this.formula_array = [...this.formulaInit]
      this.variablesGlobal.length < 1 && this.fetchGlobalVariables()
      this.variablesProduct.length < 1 && this.fetchProductVariables()
      this.showModal = true
    },
    handleCancel() {
      this.showModal = false
      this.temporary = []
      this.formula_array = [...this.formulaInit]
    },
    errAddFormula(msg = 'price_formula.error_add_variable') {
      this.$message.destroy()
      this.$message.error(this.$t(msg))
    },
    onChangeConstant(val) {
      this.constant = val
    },
    onKeyupConstant(e) {
      this.inputConstWidth = 25 + ((e.target.value.length + 1) * 8) + 'px'
    },
    onBlurConstant() {
      const { constant } = this
      if (isNumber(constant)) {
        this.formula_array.push({ constant })
      }
      this.constant = null
    },
    onClickConstant() {
      const { formula_array } = this
      const formulaLength = formula_array.length
      const lastItem = formula_array[formulaLength - 1]
      const isLastOperator = isOperator(lastItem)
      if (formulaLength > 0 && !isLastOperator && lastItem !== '(') {
        this.errAddFormula('price_formula.error_add_operator')
        return
      }
      // Rule no.2b
      if (lastItem === '(') {
        if (isLastOperator) {
          this.errAddFormula()
          return
        }
      }
      // Rule no.3b
      // if (lastItem === ')') {
      //   if (val?.symbol) {
      //     this.errAddFormula('price_formula.error_add_operator')
      //     return
      //   }
      // }
      // Rule no.4b
      // if (lastItem?.symbol || isNumber(lastItem?.constant)) {
      //   if (val?.symbol) {
      //     this.errAddFormula('price_formula.error_add_operator')
      //     return
      //   }
      // }
      this.constant = ''
      this.inputConstWidth = '25px'
    },
    onClickBracket(val) {
      const { formula_array } = this
      const formulaLength = formula_array.length
      const lastItem = formula_array[formulaLength - 1]
      // Rule no.1
      if (val === ')') {
        if (formula_array.filter(x => x === '(').length <= formula_array.filter(x => x === ')').length) {
          this.errAddFormula('price_formula.error_close_bracket')
          return
        }
      }
      // Rule no.2a
      if (lastItem === '(') {
        if (val === ')') {
          this.errAddFormula('price_formula.error_add_operator')
          return
        }
      }
      // Rule no.3a
      if (lastItem === ')') {
        if (val === '(') {
          this.errAddFormula('price_formula.error_add_operator')
          return
        }
      }
      // Rule no.4a
      if (lastItem?.symbol || isNumber(lastItem?.constant)) {
        if (val === '(') {
          this.errAddFormula('price_formula.error_add_operator')
          return
        }
      }
      this.$message.destroy()
      this.formula_array.push(val)
    },
    onClickItemModal(val) {
      const { formula_array } = this
      const formulaLength = formula_array.length
      const lastItem = formula_array[formulaLength - 1]
      const isLastOperator = isOperator(lastItem)
      if ((formulaLength < 1 && !val.symbol) || (formulaLength > 0 && isLastOperator && !val.symbol) || (formulaLength > 0 && !isLastOperator && !isOperator(val) && isNumber(lastItem.constant))) {
        this.errAddFormula('price_formula.error_add_variable')
        return
      }
      // Rule no.2b
      if (lastItem === '(') {
        if (isOperator(val)) {
          this.errAddFormula()
          return
        }
      }
      // Rule no.3b
      if (lastItem === ')') {
        if (val?.symbol) {
          this.errAddFormula('price_formula.error_add_operator')
          return
        }
      }
      // Rule no.4b
      if (lastItem?.symbol || isNumber(lastItem?.constant)) {
        if (val?.symbol) {
          this.errAddFormula('price_formula.error_add_operator')
          return
        }
      }
      this.$message.destroy()
      this.formula_array.push(val)
    },
    onClickApply(formula) {
      this.temporary = []
      if (formula.length > 0) {
        const isVariableOnly = formula.length < 2
        const lastItem = formula.at(-1) // formula[formula.length - 1] | formula.at(-1)
        const isLastOperator = ['+', '-', '*', '/'].includes(lastItem)
        if (isVariableOnly) {
          this.errAddFormula('price_formula.error_number_of_variable')
          return
        }
        if (isLastOperator) {
          this.errAddFormula()
          return
        }
        if (formula.includes('(') && !formula.includes(')')) {
          this.errAddFormula('price_formula.error_no_close')
          return
        }
        if (
          formula.filter((x) => x === '(').length !==
          formula.filter((x) => x === ')').length
        ) {
          this.errAddFormula('price_formula.error_close_bracket')
          return
        }
      }
      this.$message.destroy()
      this.form.formula = formula.map(v => typeof v === 'object' ? (v.symbol ? `{${v.symbol}}` : v.constant) : v).join('')
      this.formula_array = formula
      this.formulaInit = formula
      this.showModal = false
    },
    onAddFormula(e) {
      e.target.blur()
      this.form.formulaVariables.push({ id: '', value: '', uid: incrementId++ })
      this.formulaNames.push('')
    },
    onRemoveFormulaItem(item, idx) {
      const { formula_array, formulaNames, form } = this
      const isUse = formula_array.find((f) => f.id && f.id === formulaNames[idx])
      if (isUse) {
        this.errAddFormula('price_formula.error_use_formula')
        return
      }
      form.formulaVariables = form.formulaVariables.filter((v) => v.uid !== item.uid)
      this.formulaNames = formulaNames.filter((v, i) => i !== idx)
    },
    changeVarName(val, idx) {
      this.form.formulaVariables = this.form.formulaVariables.map((v, i) => i === idx ? { ...v, id: val } : v)
      this.formulaNames = this.formulaNames.map((v, i) => (i === idx ? val : v))
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loadingSubmit = true
          const query = this.$route.query
          const { formula_array } = this
          const { name, description, rounding, formula, formulaVariables } = this.form // , formula_variables
          const getIds = (ctgr) => formula_array.filter((v) => v.categories === ctgr).map((v) => v.id)

          await crudPriceFormula({
            method: 'post',
            data: {
              business_id: query.business_id,
              name,
              description,
              rounding,
              formula,
              formula_array,
              global_variables: getIds('GLOBAL'),
              formula_variables: formulaVariables.map(variable => ({
                id: variable.id,
                value: variable.value,
              })),
              product_variables: getIds('PRODUCT'),
            },
          })
          .then(() => {
            this.$notification.destroy()
            this.$message.success('Price Formula has been saved.')
            this.$router.push({ path: '/price-formula', query })
          })
          .catch((err) => {
            this.loadingSubmit = false
            const { message } = err?.response?.data || {}
            this.$notification.destroy()
            this.$notification.error({
              message: this.$t('utils.failed'),
              description: message || 'Failed to save Price Formula',
            })
          })
        }
      })
    },
  },
}
</script>
